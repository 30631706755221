/* eslint-disable no-undef */
import { GoogleLatLng } from 'src/redux/slices/location/types';
import { BranchLocation, ProductToBeShipCart, ShippingInfo } from 'src/types';
import { categoriesShippingFee } from '../categories';
import _ from 'lodash';

//main pc worth location: branch earnshaw
export const pcWorthMapLocation = {
  lat: 14.60511125119755,
  lng: 120.99328424781561
};

export const branchLocation: BranchLocation[] = [
  {
    branch_name: 'Earnshaw-Sampaloc',
    location: { lat: 14.60511125119755, lng: 120.99328424781561 }
  },
  {
    branch_name: 'Alabang-Zapote',
    location: { lat: 14.43577316752696, lng: 121.00972455767103 }
  }
  // Add later
  // {
  //   branch_name: 'QC-Experience',
  //   location: { lat: 14.629061730620808, lng: 121.06960873382882 }
  // }
];

//simple key found on ecomm
export const google_map_key =
  process.env.REACT_PUBLIC_GOOGLE_MAPS_API_KEY ||
  `AIzaSyCK_m4b8aDghtCbLSuxCfguxVKj45HdF3c`;

export const originCountry = 'Philippines';

export const shippingInfo: ShippingInfo[] = [
  {
    slug: 'jnt',
    title: 'J&T Express',
    img: '/static/images/logo/j&t.png',
    details: [
      'Shipping To Luzon 1-5 days',
      `Shipping To Visayas 3-7 days`,
      'Shipping To Mindanao 3-7 days',
      'Shipping To Island 5-6 days'
    ],
    shipping_fee: 0
  },
  {
    slug: 'tech-out',
    title: 'Tech Out Express (Same Day)',
    img: '/static/images/logo/kachi-motor.jpg',
    details: [
      'Delivery is today (cut off time is 3:00PM)',
      ' For Metro Manila and nearby areas of Rizal, Cavite and Bulacan',
      'Orders past 3:00 PM will be delivered tomorrow',
      'Our consigned motorcycle riders will take care of your order'
    ],
    shipping_fee: 0
  }
];

export const shippingNotes = [
  `The shipping fee for J&T is calculated based on the type of products and a percentage of the total price.`,
  `The shipping fee for TechOut is determined by the distance for km from PC Worth Earnshaw to the customer's location.`
];

export const metersToKilometers = (mtrs: number = 0) => {
  const km = mtrs / 1000;
  const kmFixed = km.toFixed(1);
  return kmFixed;
};

export const getKmDistanceFromPCWorth = async (
  customerLatLng: GoogleLatLng,
  origin?: GoogleLatLng
) => {
  try {
    const service = new google.maps.DistanceMatrixService();
    if (service && origin) {
      const response = await service?.getDistanceMatrix({
        origins: [origin],
        destinations: [customerLatLng],

        travelMode: google.maps.TravelMode.DRIVING,

        unitSystem: google.maps.UnitSystem.METRIC,
        avoidTolls: true
      });
      if (response?.rows?.length > 0) {
        if (response?.rows[0]?.elements?.length > 0) {
          const distanceInMeters =
            response?.rows[0]?.elements[0]?.distance?.value;
          const distanceInKm = metersToKilometers(distanceInMeters);
          const distanceToNum = parseFloat(distanceInKm);
          return isNaN(distanceToNum) ? 0 : distanceToNum;
        }
      }
    }
    return 0;
  } catch (error) {
    console.error(error);
  }
};

//basically we only compute it base on km away from earnshaw if techout
export const computeShippingFeeTechOut = (
  km: number = 1
  // cart: ProductToBeShipCart[]
) => {
  // NOTE: Tech Out is PHP100 base fare and PHP15 IN EVERY KM.

  // If is less than ONE make it ONE
  let roundedOffKm = km;
  if (km < 1) {
    roundedOffKm = 1;
  }
  const techOutBaseFare = 100;
  const farePerKm = 17;
  const fareWithKmAndBase = farePerKm * roundedOffKm + techOutBaseFare;

  const roundedTechOutShippingFee = Math.round(fareWithKmAndBase);
  return roundedTechOutShippingFee || 0;
};

export const computeShippingFeeJNT = (
  cart: ProductToBeShipCart[]
  //island: JntIslandType  TODO: Later on if we have kg of items.
) => {
  // TODO: Compute base on island
  const cartItemsTotalSF = cart?.reduce((acc, item) => {
    const toBeAdded = getJntCartItemSF(item);
    return toBeAdded + acc;
  }, 0);

  const cartTotalAmount = _.reduce(
    cart,
    (acc, cartItem: any) => {
      const total = acc + cartItem?.retail_price * cartItem?.quantity;
      return total;
    },
    0
  );

  const onePercentOfCartTotal = 0.01 * cartTotalAmount;
  const jntGrandTotalSF = cartItemsTotalSF + onePercentOfCartTotal;
  const roundedOffFare = Math.round(jntGrandTotalSF);

  return roundedOffFare || 0;
};

export const getJntCartItemSF = (cartItem: ProductToBeShipCart) => {
  const qty = cartItem?.quantity || 1;
  const defaultShippingFee = 250; // If category is not found lets assume it will be 250

  if (cartItem?.category_name) {
    const res = categoriesShippingFee?.find(
      (x) => x?.id === cartItem?.category_id
    );
    if (res) {
      const itemShippingFee = res?.shipping_fee || defaultShippingFee;
      const itemSFwithQty = itemShippingFee * qty;
      return itemSFwithQty;
    }
  }

  // Some endpoints returns category name only. if category_id is undefined lets use the name
  // let categoryId = cartItem?.category_id;
  if (!cartItem?.category_name && cartItem?.category_name) {
    const categoryNameUpperCase = cartItem?.category_name?.toUpperCase();
    const res = categoriesShippingFee?.find(
      (categ) => categ?.name?.toUpperCase() === categoryNameUpperCase
    );
    if (res) {
      const itemShippingFee = res?.shipping_fee || defaultShippingFee;
      const itemSFwithQty = itemShippingFee * qty;
      return itemSFwithQty;
    }
  }

  return defaultShippingFee;
};
