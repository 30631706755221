import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectFavoriteModuleState = (state: RootState) => state.favoriteModules;

const selectFavoriteModule = createSelector(
  selectFavoriteModuleState,
  (state) => state.favoriteModules
);

export const selectors = {
  selectFavoriteModule
};