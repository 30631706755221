/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

import { snakeCaseToTitleCase } from 'src/utils';
import { colors } from 'src/constants';
import RMATableRow from './components/RMATableRow';
import { RMAData } from 'src/redux/slices/rma/types';
import { initialRMATableColumns } from 'src/redux/slices/rma/constants/rma-table-columns';
import { RMAEmptySearchView } from './components/RMAEmptySearchView';
import Toolbar from './Toolbar';

const useStyles = makeStyles(() => ({
  root: {},
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  clearText: {
    color: 'rgb(244, 67, 54)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const { selectors: rmaSelector, actions: rmaActions } = slices.rma;

const RMAView = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useAppDispatch();
  const rmas = useAppSelector(rmaSelector.selectRmaList);
  // const taptapColumns = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerColumns
  // );

  // const isRMAsLoading = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerLoading
  // );

  const isRMAsLoading = false; // RMA-TODO: add proper selector of rma

  // const taptapVersion = useAppSelector(
  //   taptapCustomerSelectors.selectTapTapVersion
  // );

  // const isNextPageLoading = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerNextPageLoading
  // );
  const isNextPageLoading = false; // RMA-TODO: add proper selector of rma

  // const selectedFilter = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerFilter
  // );

  /** ============================
   * START OF SUPABASE STUFF
   ============================ */

  //  TODO: Fix any
  // const onUpdateLocalCustomerDetail = async (data: any) => {
  //   const updatedCustomerData: RMAData = data?.new ?? {};
  //   dispatch(taptapCustomerActions.updateTaptapCustomer(updatedCustomerData));
  // };

  // const onInsertOnLocalCustomerList = async (data: any) => {
  //   const newCustomerData: RMAData = data?.new ?? {};
  //   dispatch(taptapCustomerActions.insertNewTaptapCustomers(newCustomerData));
  // };

  // const onDeleteLocalCustomer = (data: any) => {
  //   const deletedCustomerData: RMAData = data?.old ?? {};
  //   dispatch(taptapCustomerActions.removeTaptapCustomer(deletedCustomerData));
  // };

  /** ============================
   * END OF SUPABASE STUFF
   ============================ */

  /** ============================
   * START OF COMPONENT STUFF
   ============================ */
  const fetchRMAs = async () => {
    dispatch(rmaActions.getRmasThunk());
  };

  // RMA-TODO: Next Page logic lods
  // const fetchCustomersNextPage = () => {
  //   if (isCustomersLoading || isNextPageLoading) {
  //     return;
  //   }

  //   dispatch(taptapCustomerActions.taptapFetchCustomersNextPageThunk());
  // };

  // const fetchTapTapProfiles = () => {
  //   dispatch(taptapGetProfilesThunk());
  // };

  /** ============================
   * END OF COMPONENT STUFF
   ============================ */

  useEffect(() => {
    fetchRMAs();
    // fetchTapTapProfiles(); // To fetch tech later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const selectedFilterIndicator = useMemo(() => {
  //   const selectedQuickFilter = findSelectedQuickFilterViaParam(selectedFilter);
  //   return selectedQuickFilter?.label || 'ALL ?';
  // }, [selectedFilter]);

  // eslint-disable-next-line no-unused-vars
  const handleScroll = (element: any) => {
    const bottom =
      Math.abs(
        element.target.scrollHeight -
          (element.target.scrollTop + element.target.clientHeight)
      ) <= 1;

    if (bottom) {
      // fetchCustomersNextPage(); RMA-TODO: dapat to nakaredux slice para pwede mahide and all
    }
  };

  return (
    <Page className={classes.root} title="Return Merchandise Authorization">
      <LoaderBar isLoading={isRMAsLoading} />
      <Toolbar onSearchCustomer={() => {}} />

      <TableContainer
        onScroll={handleScroll}
        style={{
          maxHeight: '85vh',
          overflow: 'auto',
          opacity: isRMAsLoading ? 0.3 : 1
        }}
      >
        <Table size="small">
          <TableHead
            style={{
              borderBottom: `1px solid ${colors.grey[300]}`
            }}
          >
            <TableRow>
              {initialRMATableColumns?.map((column) => {
                if (column?.visible) {
                  return (
                    <TableCell
                      style={{
                        top: 0,
                        position: 'sticky',
                        left: column.sticky ? 0 : 'auto',
                        backgroundColor: column.sticky
                          ? colors.grey[300]
                          : colors.common.white,
                        zIndex: column.sticky ? 2 : 1,
                        borderBottom: `1px solid ${colors.grey[300]}`,
                        borderRight: `1px solid ${colors.grey[300]}`
                      }}
                      key={column?.field}
                    >
                      {snakeCaseToTitleCase(column?.field)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rmas?.map((customer: RMAData) => (
              <RMATableRow key={customer?.id} rmaData={customer} />
            ))}
            {rmas?.length === 0 && <RMAEmptySearchView />}
          </TableBody>
        </Table>
        <LoaderBar isLoading={isNextPageLoading} />
      </TableContainer>
    </Page>
  );
};

export default RMAView;
