import React, { FC, memo } from 'react';
import { Box, Button, Paper, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadCSV, formatCurrency } from 'src/utils';
import { priceColumn } from 'src/utils/fast-moving-products';

const useStyles = makeStyles({
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  }
});

interface Props {
  headerKeys?: string[];
  columnIndicesToHide?: number[];
  fastMovingProductToCsv: any[];
  columnToHide: (indices: number[]) => void;
}

const component: FC<Props> = ({
  headerKeys,
  // columnIndicesToHide, //TODO: will use later for hide column function
  fastMovingProductToCsv
  // columnToHide
}) => {
  const classes = useStyles();

  //if column is hidden undefined
  //TODO: will use later
  // const checkDataIsHiddenOnColumn = (index: number, data?: any) => {
  //   return !columnIndicesToHide?.includes(index) ? data : undefined;
  // };

  const onExportToCSVFastMovingProducts = () => {
    const fastMovingProdCsv = fastMovingProductToCsv?.map((item) => {
      // TypeScript: define newItem as a Record for key-value pairs
      // eslint-disable-next-line no-undef
      const newItem: Record<string, number | string> = {};

      headerKeys?.forEach((key: string) => {
        if (priceColumn.includes(key)) {
          newItem[key] = item[key] ? formatCurrency(item[key]) : '₱0.00';
        } else {
          newItem[key] = item[key] ?? 0;
        }

        if (key === 'margin_percentage') {
          newItem[key] = item[key] ? item[key] + '%' : '0%';
        }
      });

      return newItem;
    });

    const csvName = 'FastMovingProduct_PC_Worth.csv';
    downloadCSV(fastMovingProdCsv, csvName);
  };

  return (
    <Paper style={{ marginTop: '1rem' }}>
      <Box
        className={classes.flexBox}
        padding={'1rem'}
        justifyContent={'space-between'}
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={onExportToCSVFastMovingProducts}
        >
          Export CSV
        </Button>
        {/* TODO: remove later after v2 is deployed} */}
        {/* <HideTableRowSelector
          subLabel="Fast Moving Column Filter"
          columnHeaderOptions={fastMovingProductRowHeader}
          selectedColumnToHide={(indices: number[]) => columnToHide(indices)}
        /> */}
      </Box>
    </Paper>
  );
};

export const FastMovingProductSubToolbar = memo(component);
