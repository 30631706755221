import { createStyles, TableRow, withStyles } from '@material-ui/core';
import { colors } from 'src/constants';

export const AlternatingColorTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: colors.blueGrey[100]
      }
    }
  })
)(TableRow);
