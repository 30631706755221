import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectRecentlyVisitedState = (state: RootState) => state.recentViewModules;

const selectRecentlyVisitedUrls = createSelector(
  selectRecentlyVisitedState,
  (state) => state.recentlyViewedModule
);

export const selectors = {
  selectRecentlyVisitedUrls
};