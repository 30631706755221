import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import GridOnIcon from '@material-ui/icons/GridOn';
import MemoryIcon from '@material-ui/icons/Memory';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FaceIcon from '@material-ui/icons/Face';
import ComputerIcon from '@material-ui/icons/Computer';
import PhotoIcon from '@material-ui/icons/Photo';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import RedeemIcon from '@material-ui/icons/Redeem';
import CodeIcon from '@material-ui/icons/Code';
import MoneyIcon from '@material-ui/icons/Money';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SpeedOutlinedIcon from '@material-ui/icons/SpeedOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BookIcon from '@material-ui/icons/Book';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DialpadIcon from '@material-ui/icons/Dialpad';

import { DrawerItemLabelEnum } from '../types';
import { DrawerItem } from 'src/types';
import { AssignmentOutlined, LocalPostOffice } from '@material-ui/icons';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import QueueIcon from '@material-ui/icons/Queue';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

export const user = {
  avatar: '/static/images/avatars/pcworth_logo.png',
  jobTitle: '',
  name: 'PC Worth'
};

export const drawerLogoutItem: DrawerItem = {
  href: '/login',
  icon: ExitToAppIcon,
  title: DrawerItemLabelEnum.Logout
};

const AdminUserSection = {
  sectionTitle: 'Admin / User Section',
  sectionItems: [
    {
      href: '/app/dashboard',
      icon: ShowChartIcon,
      title: DrawerItemLabelEnum.Dashboard
    },
    {
      href: '/app/account',
      icon: PersonIcon,
      title: DrawerItemLabelEnum.Account
    },
    {
      href: '/app/roles-permissions',
      icon: LockIcon,
      title: DrawerItemLabelEnum.Roles_Permissions
    },
    {
      href: '/app/users',
      icon: PeopleIcon,
      title: DrawerItemLabelEnum.Manage_Users
    },
    {
      href: '/app/audits',
      icon: HistoryIcon,
      title: DrawerItemLabelEnum.Audits
    },
    {
      href: '/app/options',
      icon: SettingsIcon,
      title: DrawerItemLabelEnum.Options
    }
  ]
};

export const drawerItemsV2: {
  sectionTitle: string;
  sectionItems: DrawerItem[];
}[] = [
  {
    sectionTitle: 'Sales Section',
    sectionItems: [
      {
        href: '/app/customer-queueing',
        icon: QueueIcon,
        title: DrawerItemLabelEnum.Customers_Queueing
      },
      {
        href: '/app/customer-taptap',
        icon: DashboardIcon,
        title: DrawerItemLabelEnum.TapTap
      },
      {
        href: '/app/ecom-orders',
        icon: ShoppingCartIcon,
        title: DrawerItemLabelEnum.Ecom_Orders
      },
      {
        href: '/app/sales',
        icon: AttachMoneyIcon,
        title: DrawerItemLabelEnum.Sales
      },
      {
        href: '/app/customers',
        icon: PeopleOutlineOutlinedIcon,
        title: DrawerItemLabelEnum.Customers
      },
      {
        href: '/app/pcw-user',
        icon: FaceIcon,
        title: DrawerItemLabelEnum.PcwUser
      },
      {
        href: '/app/price-list',
        icon: FormatListBulletedIcon,
        title: DrawerItemLabelEnum.Price_List
      },
      {
        href: '/app/quotation',
        icon: FormatQuoteIcon,
        title: DrawerItemLabelEnum.Quotation
      },
      // {
      //   href: '/app/pc-build',
      //   icon: ComputerIcon,
      //   title: DrawerItemLabelEnum.PC_Build
      // },
      {
        href: '/app/transactions',
        icon: ReceiptIcon,
        title: DrawerItemLabelEnum.Transactions
      }
    ]
  },
  {
    sectionTitle: 'Inventory Section',
    sectionItems: [
      {
        href: '/app/inventory',
        icon: HomeWorkIcon,
        title: DrawerItemLabelEnum.Inventory
      },
      {
        href: '/app/inventory-audit',
        icon: FindInPageIcon,
        title: DrawerItemLabelEnum.Inventory_Audits
      },
      {
        href: '/app/inventory-audit-logs',
        icon: ListAltIcon,
        title: DrawerItemLabelEnum.Inventory_Audits_Logs
      },
      {
        href: '/app/available-inventory',
        icon: CheckCircleOutlineIcon,
        title: DrawerItemLabelEnum.Available_Inventory
      },
      {
        href: '/app/products',
        icon: LocalMallOutlinedIcon,
        title: DrawerItemLabelEnum.Products
      },
      {
        href: '/app/out-of-stocks',
        icon: RemoveShoppingCartIcon,
        title: DrawerItemLabelEnum.Out_Of_Stock
      },
      {
        href: '/app/decaying-stocks',
        icon: HourglassEmptyIcon,
        title: DrawerItemLabelEnum.Decaying_Stock
      },
      {
        href: '/app/fast-moving-products',
        icon: SpeedOutlinedIcon,
        title: DrawerItemLabelEnum.Fast_Moving_Products
      },
      {
        href: '/app/transfer-stock',
        icon: TransferWithinAStationIcon,
        title: DrawerItemLabelEnum.Stock_Transfer
      },
      {
        href: '/app/transfer-stock-logs',
        icon: AssignmentIcon,
        title: DrawerItemLabelEnum.Transfer_Logs
      },
      {
        href: '/app/categories',
        icon: GridOnIcon,
        title: DrawerItemLabelEnum.Categories
      },
      {
        href: '/app/manufacturers',
        icon: MemoryIcon,
        title: DrawerItemLabelEnum.Manufacturers
      },
      {
        href: '/app/suppliers',
        icon: AirplanemodeActiveIcon,
        title: DrawerItemLabelEnum.Suppliers
      },
      {
        href: '/app/rma',
        icon: KeyboardReturnIcon,
        title: DrawerItemLabelEnum.RMA
      }
    ]
  },
  {
    sectionTitle: 'Marketing Section',
    sectionItems: [
      {
        href: '/app/promotional',
        icon: LoyaltyIcon,
        title: DrawerItemLabelEnum.Promotional
      },
      {
        href: '/app/games',
        icon: SportsEsportsIcon,
        title: DrawerItemLabelEnum.Games
      },
      {
        href: '/app/pc-bundles',
        icon: AssignmentOutlined,
        title: DrawerItemLabelEnum.PC_Bundles
      },
      {
        href: '/app/blog',
        icon: BookIcon,
        title: DrawerItemLabelEnum.Blogs
      },
      {
        href: '/app/blog-category',
        icon: NoteAddIcon,
        title: DrawerItemLabelEnum.Blog_Category
      },
      {
        href: '/app/ecomm-banner',
        icon: SettingsOverscanIcon,
        title: DrawerItemLabelEnum.Ecomm_Banners
      },
      {
        href: '/app/flash-deals',
        icon: FlashOnIcon,
        title: DrawerItemLabelEnum.Flash_Deals
      },
      {
        href: '/app/pc-package',
        icon: ImportantDevicesIcon,
        title: DrawerItemLabelEnum.PC_Package
      },
      {
        href: '/app/sms-blast',
        icon: LocalPostOffice,
        title: DrawerItemLabelEnum.SMS_Blast
      },
      {
        href: '/app/minigame',
        icon: RedeemIcon,
        title: DrawerItemLabelEnum.Minigame
      },
      {
        href: '/app/code-generator',
        icon: CodeIcon,
        title: DrawerItemLabelEnum.EventCode
      },
      {
        href: '/app/kachi-points',
        icon: MoneyIcon,
        title: DrawerItemLabelEnum.KachiPoints
      },
      {
        href: '/app/saved-builds',
        icon: ComputerIcon,
        title: DrawerItemLabelEnum.Saved_Builds
      },
      {
        href: '/app/gallery-list',
        icon: PhotoIcon,
        title: DrawerItemLabelEnum.PC_Build_Gallery
      },
      {
        href: '/app/shipping-fee-calculator',
        icon: DialpadIcon,
        title: DrawerItemLabelEnum.Shipping_Fee_Calculator
      }
    ]
  },
  AdminUserSection
];

// TODO: remove after final checking
if (multiBranchFeat) {
  AdminUserSection.sectionItems.push({
    href: '/app/branches',
    icon: LocationCityIcon,
    title: DrawerItemLabelEnum.Branches
  });
}
