import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { LoaderBar } from 'src/components';

interface Props {
  isLoading: boolean;
  transactionNoInput: string;
  onEnterOfTransactionNo: () => void;
  onChangeTransactionNoInput: (value: string) => void;

  serialNoInput: string;
  onEnterOfSerialNo: () => void;
  onChangeSerialNoInput: (value: string) => void;
}

const component = ({
  isLoading,
  transactionNoInput,
  onEnterOfTransactionNo,
  onChangeTransactionNoInput,

  serialNoInput,
  onEnterOfSerialNo,
  onChangeSerialNoInput,
}: Props) => {
  return (
    <Card elevation={5} style={{ marginTop: 12, marginBottom: 16 }}>
      <CardHeader title="Transaction No. or Serial No." />
      <Divider />
      <CardContent style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              id={'transaction_no'}
              variant="outlined"
              value={transactionNoInput}
              label="Existing Transaction No"
              onChange={(e) =>
                onChangeTransactionNoInput(e.target.value?.trim())
              }
              onKeyDown={(e) => {
                if (e?.key === 'Enter') {
                  onEnterOfTransactionNo();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              id={'serial_no'}
              variant="outlined"
              value={serialNoInput}
              label="Serial No"
              onChange={(e) =>
                onChangeSerialNoInput(e.target.value?.trim())
              }
              onKeyDown={(e) => {
                if (e?.key === 'Enter') {
                  onEnterOfSerialNo();
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <LoaderBar isLoading={isLoading} />
    </Card>
  );
};

export const RMATransactionNoInput = React.memo(component);
