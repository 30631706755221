/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { LoaderBar } from 'src/components';
import SearchIcon from '@material-ui/icons/Search';
import { BranchListDropDown } from 'src/components/dropdown';
import { Alert, Autocomplete } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { useDebouncedEffect, useSnackBar } from 'src/hooks';
import { Listing, ProductWithSerialNo, Transaction } from 'src/types';
import { cleanSN } from 'src/utils';

interface Props {
  onChangeSelectedItem: (value?: Listing) => void;
}

const { actions: productActions } = slices.product;
const { actions: listingActions } = slices.listing;

const component = ({ onChangeSelectedItem }: Props) => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);
  const [nonSNProducts, setNonSNProducts] = useState<ProductWithSerialNo[]>([]);

  const [productNameInput, setProductNameInput] = useState<string>('');

  const onChangeSelectedListingInternal = async (
    value?: ProductWithSerialNo | null
  ) => {
    if (!value?.serial_no) {
      snackBar.show({
        severity: 'error',
        message: `Select a proper product value`
      });
      onChangeSelectedItem(undefined);
      return;
    }

    if (!selectedBranch) {
      snackBar.show({
        severity: 'error',
        message: `Select a branch first`
      });
      onChangeSelectedItem(undefined);
      return;
    }

    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(
        listingActions.getListingViaSNThunk({
          serial_no: cleanSN(value?.serial_no),
          branch_ids: [selectedBranch]
        })
      ).finally(() => setIsLoading(false))
    );

    const listingInfo = response?.originalData?.listing;

    if (!listingInfo) {
      snackBar.show({
        severity: 'error',
        message: `Error fetching serial no details`
      });
      onChangeSelectedItem(undefined);
      return;
    }

    onChangeSelectedItem(listingInfo);
  };

  const onChangeSelectedBranch = (value?: number) => {
    setProductNameInput('');
    onChangeSelectedListingInternal(undefined);

    setSelectedBranch(value);
  };

  const getNonSnProducts = async () => {
    if (!selectedBranch) {
      return;
    }

    setIsProductsLoading(true);
    const response = unwrapResult(
      await dispatch(
        productActions.getProductsWithSerialThunk({
          keyword: productNameInput,
          except_serial_no: [],
          branch_ids: [selectedBranch]
        })
      ).finally(() => setIsProductsLoading(false))
    );

    const filteredProducts = response?.originalData?.products?.filter((x) =>
      x?.serial_no?.includes('PCWSN-')
    );

    setNonSNProducts(filteredProducts);
  };

  useDebouncedEffect(getNonSnProducts, 500, [productNameInput]);

  return (
    <Card elevation={5} style={{ marginTop: 12, marginBottom: 16 }}>
      <CardHeader title="Non-SN Product" />
      <Alert severity="info">THIS SECTION IS FOR STOCK UNITS ONLY</Alert>
      <Divider />
      <CardContent style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              size="small"
              loading={isProductsLoading}
              inputValue={productNameInput}
              onInputChange={(_, newInputValue, reason) =>
                setProductNameInput(newInputValue)
              }
              id="product_name"
              options={nonSNProducts}
              getOptionLabel={(option) => `${option?.product_name}`}
              onChange={(_, newValue) =>
                onChangeSelectedListingInternal(newValue)
              }
              disabled={!selectedBranch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  helperText={
                    !selectedBranch
                      ? 'select a branch first to search products'
                      : 'select a product'
                  }
                  name="product_name"
                  label="Non-serialized - Product Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <BranchListDropDown
              size="small"
              paperSpace={0}
              isElevated={false}
              onHandleBranchChange={(branchIds?: number[]) => {
                onChangeSelectedBranch(branchIds?.[0]);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <LoaderBar isLoading={isLoading} />
    </Card>
  );
};

export const RMANonSNSelector = React.memo(component);
