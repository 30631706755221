import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { usePermissions } from './use-permissions';
import { drawerItemsV2 } from 'src/layouts/DashboardLayout/constants';
import { DrawerItemLabelEnum } from 'src/layouts/DashboardLayout/types';

export const useDrawerItemsWithPerms = () => {
  const permissions = usePermissions();

  const drawerItemsWithPermissions = useMemo(() => {
    const clonedDrawerItems = cloneDeep(drawerItemsV2);
    const canViewSales = permissions.permissionChecker('view_sales');
    const canManageUsers = permissions.permissionChecker('manage_users');
    const canViewBanners = permissions.permissionChecker('view_ecomm_banners');
    const canViewAvailableInventory = permissions.canSeeAvailableInventory;
    const canManagePermissions = permissions.permissionChecker(
      'edit_permissions_roles'
    );
    const canViewFlashDeals = permissions.canViewFlashDeals;
    const canViewEcomUserAccount = permissions.canViewEcomUserAccount;
    const canEditBranches = permissions.canEditBranches;
    const canEditOptions = permissions.canEditOptions;
    const canSMSBlast = permissions.canSMSBlast;
    const canViewCodeEvent = permissions.canViewCodeEvent;
    const canViewMinigame = permissions.canViewMiniGame;
    const canViewKachiPoints = permissions.canViewKachiPoints;
    const featureToggleKachiPoints = permissions.featureToggleKachiPoints;
    const viewStockTransfer = permissions?.canViewStockTransfer;
    const viewTransferLogs = permissions?.canViewStockTransferLogs;
    const viewOutOPfStockProducts = permissions?.viewRecentOutOfStockProducts;
    const viewPriceListV2 = permissions?.viewPricelist;
    const viewDecayingStock = permissions?.canViewDecayingStocks;
    const viewFastMovingProducts = permissions?.canViewFastMovingProducts;
    const viewEcommSavedBuilds = permissions?.canViewEcommSavedBuilds;
    const viewBlog = permissions?.canViewBlog;
    const canViewBlogCategory = permissions?.canViewBlogCategory;

    const canViewInventoryAudit = permissions?.canInventoryAudit;
    const canViewInventoryAuditLogs = permissions?.canViewAuditLogs;
    const canCreatePromotional = permissions?.canCreatePromotional;

    // RMA
    const canViewRMA = permissions?.canViewRMA;

    const filteredViaPermissions = clonedDrawerItems?.map((y) => {
      const filteredSectionItems = y?.sectionItems?.filter((x) => {
        if (x.title === DrawerItemLabelEnum.Sales && !canViewSales) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Manage_Users && !canManageUsers) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Roles_Permissions &&
          !canManagePermissions
        ) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Ecomm_Banners && !canViewBanners) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Flash_Deals && !canViewFlashDeals) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Available_Inventory &&
          !canViewAvailableInventory
        ) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Options && !canEditOptions) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.SMS_Blast && !canSMSBlast) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.PcwUser &&
          !canViewEcomUserAccount
        ) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.EventCode && !canViewCodeEvent) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Minigame && !canViewMinigame) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Branches && !canEditBranches) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.KachiPoints &&
          !canViewKachiPoints &&
          !featureToggleKachiPoints
        ) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Stock_Transfer &&
          !viewStockTransfer
        ) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Transfer_Logs &&
          !viewTransferLogs
        ) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Out_Of_Stock &&
          !viewOutOPfStockProducts
        ) {
          return false;
        }
        if (x.title === DrawerItemLabelEnum.Price_List_V2 && !viewPriceListV2) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Decaying_Stock &&
          !viewDecayingStock
        ) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Fast_Moving_Products &&
          !viewFastMovingProducts
        ) {
          return false;
        }
        if (
          x.title === DrawerItemLabelEnum.Saved_Builds &&
          !viewEcommSavedBuilds
        ) {
          return false;
        }
        //===blog===
        if (x.title === DrawerItemLabelEnum.Blogs && !viewBlog) {
          return false;
        }

        if (
          x.title === DrawerItemLabelEnum.Blog_Category &&
          !canViewBlogCategory
        ) {
          return false;
        }

        //=== inventory audit ===
        if (
          x.title === DrawerItemLabelEnum.Inventory_Audits &&
          !canViewInventoryAudit
        ) {
          return false;
        }

        if (
          x.title === DrawerItemLabelEnum.Inventory_Audits_Logs &&
          !canViewInventoryAuditLogs
        ) {
          return false;
        }

        if (
          x.title === DrawerItemLabelEnum.Promotional &&
          !canCreatePromotional
        ) {
          return false;
        }

        // RMA
        if (x.title === DrawerItemLabelEnum.RMA && !canViewRMA) {
          return false;
        }

        return true;
      });

      const newItem = {
        sectionTitle: y?.sectionTitle,
        sectionItems: filteredSectionItems
      };
      return newItem;
    });

    const filteredIfHasItems = filteredViaPermissions?.filter(
      (x) => x?.sectionItems?.length > 0
    );

    return filteredIfHasItems;
  }, [permissions]);
  return { drawerItemsWithPermissions };
};
