import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';

import { DrawerItem, DrawerSection, LogoutDialog } from 'src/components';
import { drawerLogoutItem, user } from '../constants';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { DrawerItemLabelEnum } from '../types';
import { DrawerItem as DrawerItemType } from 'src/types';
import { appInformation } from 'src/constants';

interface Props {
  onMobileClose: () => void;
  openMobile: boolean;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: undefined,
    height: 64
  },
  name: {
    marginTop: 10
  },
  listContainer: {
    flex: 1
  },
  logout: {
    marginLeft: 15
  }
}));

const { actions: userActions, selectors: userSelectors } = slices.user;

export const MainDrawer = ({ onMobileClose, openMobile }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(userSelectors.selectUserInfo);

  const [activeDrawerItem, setActiveDrawerItem] = useState<
    DrawerItemLabelEnum | undefined
  >(DrawerItemLabelEnum.Dashboard);
  const [logoutDialogVisibility, setLogoutDialogVisibility] = useState<boolean>(
    false
  );

  const onClickItem = (item: DrawerItemType) => {
    setActiveDrawerItem(item.title);
    if (item.title === DrawerItemLabelEnum.Logout) {
      setLogoutDialogVisibility(true);
      return;
    }
    navigate(item.href);
  };

  const onDismissDialog = () => {
    setLogoutDialogVisibility(false);
  };

  const onLogOut = async () => {
    await dispatch(userActions.logout());
    navigate('/login', { replace: true });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, onMobileClose, openMobile]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <div className={classes.listContainer}>
        <Box alignItems="center" display="flex" flexDirection="column" p={2}>
          <a href="/app/welcome-page">
            <img
              alt="Under development"
              className={classes.avatar}
              src="/static/images/avatars/pcworth_logo.png"
            />
          </a>
          <Typography className={classes.name} color="textPrimary" variant="h5">
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {`${userInfo?.first_name || ''} ${userInfo?.last_name || ''} `}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <DrawerSection />
        </Box>
      </div>

      <div className={classes.logout}>
        <DrawerItem
          data={drawerLogoutItem}
          key={drawerLogoutItem.title}
          onClick={() => onClickItem(drawerLogoutItem)}
          isActive={drawerLogoutItem.title === activeDrawerItem}
        />
      </div>

      <Box alignItems="center" flexDirection="column" p={3}>
        <Typography variant="subtitle2" color="textSecondary">
          {appInformation.name}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Version: {appInformation.version}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          ENV: {appInformation.env}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <LogoutDialog
        onLogout={onLogOut}
        onDismiss={onDismissDialog}
        isVisible={logoutDialogVisibility}
      />
    </>
  );
};
