import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducers } from './reducers';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'favoriteModules'], // Things u want to persist.
  blacklist: [] // Things you do not want to.
};

const persistedReducer = persistReducer(persistConfig, reducers);

// type ExtractReducerGeneric<Type> = Type extends Reducer<infer X> ? X : never;

export const createStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([thunk])
  });

export const store = createStore();
export const persistedStore = persistStore(store);

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;

