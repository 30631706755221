import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

const { actions: userActions, selectors: userSelectors } = slices.user;
const useUserInfo = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const userDetails = useAppSelector(userSelectors.selectUserInfo);
  const favoriteBranch = useAppSelector(userSelectors.selectFavoriteBranch);
  const userBranches = userDetails?.branches;
  const userBranchIds = userDetails?.branches?.map((b) => b.id);

  const setFavoriteBranch = (id: number | null) => {
    dispatch(userActions.setFavoriteBranch(id));
  };

  const getUserDetails = useCallback(async () => {
    if (!multiBranchFeat) {
      return;
    }

    if (id) {
      const user_id = +id;
      if (isNaN(user_id)) {
        return;
      }
      const response = unwrapResult(
        await dispatch(userActions.getUserThunk(user_id))
      );
      if (response.success && response.originalData?.user) {
        dispatch(userActions.updateAccountInfo(response.originalData?.user));
      }
    }
  }, [dispatch, id]);

  const userBranchOptions = useMemo(() => {
    return (
      userBranches?.map((b) => ({ name: b.branch_name, value: b.id })) || []
    );
  }, [userBranches]);

  const allBranchIDs = useMemo(() => {
    return userBranchOptions?.map((i) => i.value) ?? [];
  }, [userBranchOptions]);

  const userBranchNameViaId = useCallback(
    (id?: number | null) => {
      return userBranchOptions?.find((b) => b.value === id)?.name;
    },
    [userBranchOptions]
  );

  const defaultValueBranch = useMemo(() => {
    const branchIds: number[] = userBranchOptions.map(
      (branch) => branch?.value
    );
    return branchIds.length > 0 ? [branchIds[0]] : [];
  }, [userBranchOptions]);

  const userBranchIdDefaultOption = useMemo(() => {
    if (userBranches && userBranches?.length > 0) {
      return userBranches[0].id;
    }
    return undefined;
  }, [userBranches]);

  return {
    id,
    userBranchIds,
    userDetails,
    favoriteBranch,
    userBranches,
    allBranchIDs,
    userBranchOptions,
    userBranchIdDefaultOption,
    defaultValueBranch,
    userBranchNameViaId,
    setFavoriteBranch,
    getUserDetails,
  };
};

export default useUserInfo;
