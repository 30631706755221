import React, { useEffect, useState } from 'react';
import { pickBy, isEqual, isEmpty } from 'lodash';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import { DeleteDialog, LoaderBar, Page } from 'src/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import RMAForm from './components/RMAForm';
import { usePermissions, useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { rmaDetailsFields } from 'src/redux/slices/rma/constants/rma-form-fields';
import { RMAField } from 'src/redux/slices/rma/constants/rma-fields';
import { RMAData, RMAImage } from 'src/redux/slices/rma/types';

const { actions: rmaActions } = slices.rma;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    paddingBottom: 20,
    paddingTop: 20
  },
  contactCard: {
    marginTop: 20
  }
}));

interface FormValues {
  [key: string]: any; // could string, boolean, number, etc
}

const initialValues: FormValues = {
  // first_name: '',
  // last_name: '',
  // contact_no: '',
  // address: '',
  // source: '',
  // branch_released: '',
  // courier: '',
  // service_type: '',
  // total_amount: 0,
  // shipping_fee: 0,
  // is_shipping_fee_paid: false,
  // is_agree_marketing: false,
  // is_agree_tnc: false,
  // trial_os: '',
  // warranty_slip_no: '',
  // type_of_build: '',
  // with_upgrade: '',
  // kind_of_sales_transfer: '',
  // status: '',
  // build_tag: ''
};

const RMADetailsView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const { canDeleteRMA, canUpdateRMA } = usePermissions();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [cleanRMAData, setCleanRMAData] = useState<RMAData>({});
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(
    false
  );

  const fetchTapTapCustomerDetails = async () => {
    setIsLoading(true);

    if (id) {
      const getRmaRes = unwrapResult(
        await dispatch(rmaActions.getRmaViaIdThunk(Number(id)))
      );

      if (!getRmaRes?.success) {
        snackbar.show({
          severity: 'error',
          message: getRmaRes?.message || 'Error Getting Data'
        });
        console.error('errData', getRmaRes?.message || 'Error Getting Data');
        setIsLoading(false);
        return;
      }

      if (getRmaRes?.originalData?.data) {
        const rmaData: RMAData = getRmaRes?.originalData?.data;

        formik.setValues(rmaData);
        // Save this clean data to compare later if the server has made changes
        setCleanRMAData(rmaData);

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (newValues: any) => {
    setIsLoading(true);

    if (cleanRMAData?.is_deleted) {
      setIsLoading(false);

      console.error('This RMA has been deleted');
      alert('This RMA has been deleted already');
      return;
    }

    const changedValues = pickBy(
      newValues,
      (value, key) => !isEqual(value, cleanRMAData[key])
    );

    if (isEmpty(changedValues)) {
      setIsLoading(false);
      snackbar.show({
        severity: 'error',
        message: JSON.stringify('No changes detected')
      });
      return;
    }

    console.log('changedValues', changedValues);
    const updateRmaRes = unwrapResult(
      await dispatch(
        rmaActions.updateRmaThunk({ ...changedValues, rma_id: Number(id) })
      )
    );

    if (!updateRmaRes?.success) {
      setIsLoading(false);

      console.error('errData', updateRmaRes?.message || 'Error Updating Data');
      snackbar.show({
        severity: 'error',
        message: JSON.stringify(updateRmaRes?.message || 'Error Updating Data')
      });
      return;
    }

    if (updateRmaRes?.success) {
      setIsLoading(false);

      // const response: TapTapCustomerData = data[0];

      if (updateRmaRes?.originalData?.data) {
        formik.setValues(updateRmaRes?.originalData?.data);
        setCleanRMAData(updateRmaRes?.originalData?.data);
        snackbar.show({
          severity: 'success',
          message: 'Customer updated successfully'
        });
      }

      return;
    }
  };

  const onDeleteCustomer = async () => {
    setIsDeleteDialogVisible(false);

    const deleteRmaRes = unwrapResult(
      await dispatch(rmaActions.rmaDeleteThunk(Number(id)))
    );

    console.log('deleteRmaRes', deleteRmaRes);

    if (!deleteRmaRes?.success) {
      snackbar.show({
        severity: 'error',
        message: deleteRmaRes?.message || 'Error Deleting Data'
      });
      console.error('errData', deleteRmaRes?.message || 'Error Deleting Data');
      return;
    }

    if (deleteRmaRes?.success) {
      snackbar.show({
        severity: 'success',
        message: 'RMA deleted successfully'
      });
      navigate('/app/rma');
      return;
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  const onDeleteImage = async (rma_transaction_image_id: number) => {
    console.log('onDeleteImage', rma_transaction_image_id);

    const deleteRmaImgsRes = unwrapResult(
      await dispatch(rmaActions.deleteRmaImageThunk(rma_transaction_image_id))
    );

    if (deleteRmaImgsRes?.success) {
      const newImgsForState = cleanRMAData?.images?.filter(
        (x: RMAImage) => x.rma_transaction_image_id !== rma_transaction_image_id
      );

      formik.setValues((prev) => ({ ...prev, images: newImgsForState }));
      setCleanRMAData((prev) => ({ ...prev, images: newImgsForState }));
    }

    console.log(deleteRmaImgsRes);
  };

  const onUploadImages = async (newlySelectedImages: RMAImage[]) => {
    const uploadRmaImgsRes = unwrapResult(
      await dispatch(
        rmaActions.uploadRmaImagesThunk({
          images: newlySelectedImages,
          rma_id: Number(id)
        })
      )
    );

    const newImgsForState = uploadRmaImgsRes?.originalData?.data?.images;

    formik.setValues((prev) => ({ ...prev, images: newImgsForState }));
    setCleanRMAData((prev) => ({ ...prev, images: newImgsForState }));
  };

  const onChangeImages = async (newImagesValue: RMAImage[]) => {
    const newlySelectedImages = newImagesValue.filter((x) => x?.file);
    if (newlySelectedImages.length > 0) {
      onUploadImages(newlySelectedImages);
      return;
    }

    // const oldImages = cleanRMAData?.images;

    const deletedElement = cleanRMAData?.images?.filter(
      (item: RMAImage) =>
        !newImagesValue.some(
          (newItem) =>
            newItem.rma_transaction_image_id === item.rma_transaction_image_id
        )
    );

    if (deletedElement?.length > 0) {
      onDeleteImage(deletedElement[0].rma_transaction_image_id);
    }
  };

  const onFormikFieldChange = (field: string, value: any) => {
    if (field === RMAField.RMA_IMAGES) {
      onChangeImages(value);
    }
  };

  useEffect(() => {
    fetchTapTapCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="RMA Details">
      <Container maxWidth={false} style={{ opacity: isLoading ? 0.3 : 1 }}>
        {formik?.values?.is_deleted && (
          <Alert
            hidden
            severity="error"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Typography variant="h3">
              RMA IS ALREADY DELETED. YOU CANNOT EDIT THIS RMA ANYMORE.
            </Typography>
          </Alert>
        )}
        <LoaderBar isLoading={isLoading} />
        <Box>
          <Typography variant="h3">
            RMA Details - {`${formik?.values?.customer_name}`}
          </Typography>

          <Typography variant="h6">
            By:{' '}
            {`${formik?.values?.created_by} on ${formik?.values?.created_at}`}
          </Typography>

          <Typography variant="h6">
            Time Spent: {formik?.values?.turnaround_time}
          </Typography>
        </Box>

        <Box mt={2}>
          <RMAForm
            formikHookRef={formik}
            formFields={rmaDetailsFields}
            onChange={onFormikFieldChange}
          />
        </Box>

        {canUpdateRMA ? (
          <Button
            fullWidth
            onClick={async () => {
              const validateForm = await formik.validateForm();
              if (validateForm && Object.keys(validateForm).length > 0) {
                const errorFields = Object.keys(validateForm).join(', ');
                const message = `Please check the following fields: ${errorFields}`;
                snackbar.show({ severity: 'error', message, useSound: true });
                return;
              }

              formik.handleSubmit();
            }}
            color="primary"
            variant="contained"
            style={{ padding: 30, marginTop: 50 }}
            disabled={isLoading}
          >
            Save RMA
          </Button>
        ) : null}

        {canDeleteRMA ? (
          <Button
            fullWidth
            variant="text"
            color="secondary"
            style={{ marginTop: 20 }}
            onClick={() => setIsDeleteDialogVisible(true)}
          >
            Delete RMA
          </Button>
        ) : null}
      </Container>

      <DeleteDialog
        title="Delete RMA"
        subTitle={`Are you sure you want to delete this RMA?`}
        isVisible={isDeleteDialogVisible}
        handleClose={() => setIsDeleteDialogVisible(false)}
        onDelete={onDeleteCustomer}
      />
      {/* <DecisionDialog
        isOpen={amountChangedDialog}
        title="Total Amount Has Changed"
        subTitle={totalChangedDialogCopy}
        onHandleConfirmAction={() => theTotalAmountHasChangedThusUpdateIt()}
        onHandleClose={() => setAmountChangedDialog(false)}
      /> */}
    </Page>
  );
};

export default RMADetailsView;

const formSchema = yup.object().shape({
  [RMAField.CUSTOMER_NAME]: yup.string().required('Customer Name is required'),
  [RMAField.LOCATION]: yup.string().required('Location is required'),
  [RMAField.STATUS]: yup.string().required('Status is required'),
  [RMAField.REPORTED_PROBLEM]: yup
    .string()
    .required('Reported Problem is required'),
  [RMAField.CONTACT_NO]: yup
    .string()
    .nullable()
    .matches(/\d+$/, 'Contact number must be numeric')
});
