import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { CreateListingProduct, Product } from 'src/types';
import { formatCurrency, formatDate } from 'src/utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ProductTypeEnum } from 'src/enums';
import { Alert } from '@material-ui/lab';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import useResolution from 'src/hooks/useResolution';
import { AlternateRowContainer } from './AlternateRowContainer';
import { TextRow } from './TextRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isEmpty } from 'lodash';

interface TemporaryListingsProps {
  product?: Product;
  subHeader?: string;
  recentListings: CreateListingProduct[];
  onPressDeleteRecentItem: (i: number) => void;
  showOnlySerialNo: boolean;
}

const useStyles = makeStyles((theme) => ({
  consumableAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  mobileSubText: {
    fontSize: '.8em',
    fontWeight: 'bold',
    color: 'rgb(84, 110, 122)'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    margin: '1em 0'
  },
  mainDetails: {
    display: 'flex',
    rowGap: '.1em',
    flexDirection: 'column',
    padding: '.5em'
  },
  scrollContainer: {
    position: 'relative',
    height: '100%'
  }
}));

const Listings = ({
  recentListings,
  onPressDeleteRecentItem,
  product,
  subHeader,
  showOnlySerialNo
}: TemporaryListingsProps) => {
  const classes = useStyles();
  const { isMobile } = useResolution();
  const isProductConsumable = useMemo(
    () => product?.product_type === ProductTypeEnum.Consumable,
    [product]
  );
  const [isCardVisible, setIsCardVisible] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsCardVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1
      }
    );

    if (scrollContainerRef.current) {
      observer.observe(scrollContainerRef.current);
    }

    return () => {
      if (scrollContainerRef.current) {
        observer.unobserve(scrollContainerRef.current);
      }
    };
  }, []);

  // TODO: Replace once TS is upgraded
  const itemQty = useCallback((param: number | undefined) => param || 0, []);

  const tempListingsLength = useMemo(() => {
    if (recentListings?.length) {
      if (isProductConsumable) {
        const totalConsumableQty =
          recentListings?.reduce(
            (accumulator, item) =>
              +accumulator + +itemQty(item?.consumable_qty) || 0,
            0
          ) || 0;
        return totalConsumableQty;
      }
      return recentListings?.length;
    }
    return 0;
  }, [isProductConsumable, itemQty, recentListings]);

  return isMobile ? (
    <Box marginY={2}>
      {isProductConsumable ? (
        <Alert hidden className={classes.consumableAlert} severity="info">
          This product is consumable and does not have serial numbers under it.
        </Alert>
      ) : null}
      <Card>
        <Typography style={{ fontWeight: 'bold' }}>
          Temporary Listings - Quantity: {tempListingsLength}
        </Typography>
        <Typography className={classes.mobileSubText}>{subHeader}</Typography>
        <Typography className={classes.mobileSubText}>
          Temporary listings to be uploaded to the database..
        </Typography>

        <div ref={scrollContainerRef} className={classes.scrollContainer}>
          <PerfectScrollbar>
            {isProductConsumable ? (
              <div>
                {recentListings?.map((item, ind) => (
                  <AlternateRowContainer key={ind} index={ind}>
                    <div className={classes.mainDetails}>
                      <TextRow
                        label="Qty"
                        value={String(item.consumable_qty)}
                      />
                      <TextRow label="ID" value={item.serial_no || 'N/A'} />
                      <TextRow label="Supplier" value={item?.supplier} />
                      <TextRow label="Branch" value={item?.branch_name} />
                      <TextRow
                        label="Purchase Date"
                        value={formatDate(item?.purchase_date)}
                      />
                      <TextRow
                        label="Dealers Price"
                        value={formatCurrency(item?.dealers_price)}
                      />
                      <TextRow
                        label="Retail Price"
                        value={formatCurrency(item?.retail_price)}
                      />
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{ fontSize: '.7em', padding: '.6em' }}
                        onClick={() => onPressDeleteRecentItem(ind)}
                      >
                        Remove Item
                      </Button>
                    </div>
                  </AlternateRowContainer>
                ))}
              </div>
            ) : (
              <div>
                {recentListings?.map((item, ind) => (
                  <AlternateRowContainer key={ind} index={ind}>
                    <div className={classes.mainDetails}>
                      <TextRow label="Serial" value={item.serial_no} />
                      {!showOnlySerialNo && (
                        <>
                          <TextRow label="Branch" value={item.branch_name} />
                          <TextRow label="Supplier" value={item.supplier} />
                          <TextRow
                            label="Dealers Price"
                            value={formatCurrency(item?.dealers_price)}
                          />
                          <TextRow
                            label="Retail Price"
                            value={formatCurrency(item?.retail_price) ?? '--'}
                          />
                        </>
                      )}
                      <div className={classes.buttonContainer}>
                        <Button
                          color="secondary"
                          variant="contained"
                          style={{ fontSize: '.7em', padding: '.6em' }}
                          onClick={() => onPressDeleteRecentItem(ind)}
                        >
                          Remove Item
                        </Button>
                      </div>
                    </div>
                  </AlternateRowContainer>
                ))}
              </div>
            )}
          </PerfectScrollbar>
        </div>
        {!isEmpty(recentListings) && recentListings.length >= 3 ? (
          <>
            {isCardVisible && (
              <Tooltip title="Scroll to Bottom" placement="left">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  aria-label="down"
                  onClick={() =>
                    window?.scrollTo(0, document?.body?.scrollHeight)
                  }
                  style={{
                    position: 'fixed',
                    bottom: 30,
                    left: '',
                    borderRadius: '50%',
                    minWidth: '56px',
                    height: '56px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    zIndex: 1000
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Button>
              </Tooltip>
            )}
          </>
        ) : null}
      </Card>
    </Box>
  ) : (
    <Box marginY={2}>
      {isProductConsumable ? (
        <Alert hidden className={classes.consumableAlert} severity="info">
          This product is consumable and does not have serial numbers under it.
        </Alert>
      ) : null}
      <Card>
        <CardHeader
          title={`Temporary Listings - Quantity: ${tempListingsLength}`}
          subheader={`${subHeader} | Temporary listings to be uploaded to the database`}
        />
        <Divider />
        <div ref={scrollContainerRef} className={classes.scrollContainer}>
          <PerfectScrollbar>
            <Box>
              {isProductConsumable ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Qty</TableCell>
                      <TableCell>ID</TableCell>
                      {!showOnlySerialNo && (
                        <>
                          <TableCell>Supplier</TableCell>
                          {multiBranchFeat ? (
                            <TableCell>Branch</TableCell>
                          ) : null}
                          <TableCell>Purchase Date</TableCell>
                          <TableCell>Dealers Price</TableCell>
                          <TableCell>Retail Price</TableCell>
                        </>
                      )}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentListings?.map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>{item?.consumable_qty}</TableCell>
                        <TableCell>{item?.serial_no || 'N/A'}</TableCell>
                        {!showOnlySerialNo && (
                          <>
                            <TableCell>{item?.supplier}</TableCell>
                            {multiBranchFeat ? (
                              <TableCell>{item?.branch_name}</TableCell>
                            ) : null}
                            <TableCell>
                              {formatDate(item?.purchase_date)}
                            </TableCell>
                            <TableCell>
                              {`DP ${formatCurrency(item?.dealers_price) ??
                                '--'}`}
                            </TableCell>
                            <TableCell>
                              {`SRP ${formatCurrency(item?.retail_price) ??
                                '--'}`}
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <IconButton
                            onClick={() => onPressDeleteRecentItem(i)}
                          >
                            <DeleteForeverIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Serial No.</TableCell>
                      {!showOnlySerialNo && (
                        <>
                          <TableCell>Supplier</TableCell>
                          {multiBranchFeat ? (
                            <TableCell>Branch</TableCell>
                          ) : null}
                          <TableCell>Dealers Price</TableCell>
                          <TableCell>Price</TableCell>
                        </>
                      )}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentListings?.map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>{item?.serial_no || 'N/A'}</TableCell>
                        {!showOnlySerialNo && (
                          <>
                            <TableCell>{item.supplier}</TableCell>
                            {multiBranchFeat ? (
                              <TableCell>{item?.branch_name}</TableCell>
                            ) : null}
                            <TableCell>
                              {`DP ${formatCurrency(item?.dealers_price) ??
                                '--'}`}
                            </TableCell>
                            <TableCell>
                              {`SRP ${formatCurrency(item?.retail_price) ??
                                '--'}`}
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <IconButton
                            onClick={() => onPressDeleteRecentItem(i)}
                          >
                            <DeleteForeverIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
        </div>

        {!isEmpty(recentListings) && recentListings.length >= 3 ? (
          <>
            {isCardVisible && (
              <Tooltip title="Scroll to Bottom" placement="left">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  aria-label="down"
                  onClick={() =>
                    window?.scrollTo(0, document?.body?.scrollHeight)
                  }
                  style={{
                    position: 'fixed',
                    bottom: 16,
                    right: 25,
                    borderRadius: '50%',
                    minWidth: '56px',
                    height: '56px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    zIndex: 1000
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Button>
              </Tooltip>
            )}
          </>
        ) : null}
      </Card>
    </Box>
  );
};

export const TemporaryListings = React.memo(Listings);
