import { RMAColumn } from '../types';
import { RMAField } from './rma-fields';
import { rmaLocationOptions, rmaOptionsStatuses } from './rma-form-fields';

export const initialRMATableColumns: RMAColumn[] = [
  {
    field: RMAField.SERIAL_NO,
    type: 'custom',
    visible: true,
    sticky: true
  },
  {
    field: RMAField.STATUS,
    type: 'autocomplete',
    visible: true,
    minWidth: 300,
    options: rmaOptionsStatuses
  },
  {
    field: RMAField.PRODUCT_NAME,
    type: 'text',
    visible: true
  },
  {
    field: RMAField.LOCATION,
    type: 'autocomplete',
    visible: true,
    minWidth: 300,
    options: rmaLocationOptions
  },
  {
    field: RMAField.REPORTED_PROBLEM,
    type: 'text',
    visible: true
  },
  {
    field: RMAField.CUSTOMER_NAME,
    type: 'text',
    visible: true,
  },
  {
    field: RMAField.REMARKS,
    type: 'text',
    visible: true
  },
  {
    field: RMAField.RMA_IMAGES,
    type: 'image',
    visible: true
  },
  {
    field: RMAField.CONTACT_NO,
    type: 'text',
    visible: true
  },
  {
    field: RMAField.TECH,
    type: 'text',
    visible: true
  },
  {
    field: RMAField.CREATED_BY,
    type: 'text',
    visible: true
  },
  {
    field: RMAField.CREATED_AT,
    type: 'text',
    visible: true
  },

];
