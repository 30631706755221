import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Module {
  url: string;
  title: string;
  isFavorite: boolean;
}
interface State {
  favoriteModules: Module[];
}
const initialState: State = {
  favoriteModules: []
};
const slice = createSlice({
  name: 'favoriteModules',
  initialState,
  reducers: {
    addModulesToFavorite(state, action: PayloadAction<Module>) {
      const filteredModules = state.favoriteModules.filter(
        (item) => item.url !== action.payload.url
      );

      filteredModules.unshift(action.payload);

      state.favoriteModules = filteredModules;
    },
    removeModulesToFavorite(state, action: PayloadAction<Module>) {
      state.favoriteModules = state.favoriteModules.filter(
        (item) => item.url !== action.payload.url
      );
    },
  }
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions
};
