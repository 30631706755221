import React from 'react';
import {
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { Transaction } from 'src/types';

interface Props {
  transactionItem: Transaction;
  onSelectTransactionItem: (item: Transaction) => void;
}

const component = ({ transactionItem, onSelectTransactionItem }: Props) => {
  return (
    <ListItem divider dense>
      <ListItemText
        secondary={transactionItem?.product_name}
        primary={transactionItem?.serial_no}
      />
      <ListItemSecondaryAction>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          style={{ marginLeft: 10 }}
          disabled={transactionItem?.isDeleted ? true : false}
          onClick={() => onSelectTransactionItem(transactionItem)}
        >
          Select
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const RMATransactionItem = React.memo(component);
