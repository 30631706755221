import React, { useCallback } from 'react';
import {
  Button,
  Card,
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/Link';
import DvrIcon from '@material-ui/icons/Dvr';

import {
  CustomInputEvent,
  Quotation,
  QuotationCategory,
  QuotationProduct
} from 'src/types';
import {
  copyToClipboard,
  formatCurrency,
  openInNewTab,
  promoPriceToSRP
} from 'src/utils';
import { QuotationTotalPrice } from './QuotationTotalPrice';
import { QuotationQuantityCell } from './QuotationQuantityCell';
import { QuotationProductCell } from './QuotationProductCell';
import { QuotationCategoryCell } from './QuotationCategoryCell';
import { QuotationPromoPriceCell } from './QuotationPromoPriceCell';
import { QuotationSRPCell } from './QuotationSRPCell';
//TODO: fix later to allow image on quotation selected products
// import QuotationProductImageCell from './QuotationProductImageCell';

interface QuotationMainTableProps {
  items: Quotation[];
  branchId?: number;
  showSRP: boolean;
  showCleanSrpQuote: boolean;
  hasCompatibility: boolean;
  categoryOptions: QuotationCategory[];
  productOptions: QuotationProduct[];
  isCategoryLoading?: boolean;
  onRemoveItem: (i: number) => void;
  onChangeQuantity: (e: CustomInputEvent, index: number) => void;
  onCustomCategInputChange: (e: CustomInputEvent, index: number) => void;
  onCustomProductInputChange: (e: CustomInputEvent, index: number) => void;
  onCustomPriceInputChange: (e: CustomInputEvent, index: number) => void;
  onChangeCategory: (val: QuotationCategory | null, index: number) => void;
  onChangeProduct: (val: QuotationProduct | null, index: number) => void;
}

const useStyles = makeStyles(() => ({
  root: {},
  table: {
    backgroundSize: '70px 60px',
    backgroundRepeat: 'repeat',
    background:
      'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,.95)), url("/static/images/avatars/pcworth_logo.png")'
  },
  amount: {
    fontWeight: 500
  },
  promoPriceHeaderTxt: {}
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.disabledBackground
      }
    }
  })
)(TableRow);

export const MainTable = ({
  items,
  branchId,
  showSRP,
  categoryOptions,
  productOptions,
  showCleanSrpQuote,
  hasCompatibility,
  isCategoryLoading,
  onRemoveItem,
  onChangeQuantity,
  onChangeCategory,
  onChangeProduct,
  onCustomCategInputChange,
  onCustomProductInputChange,
  onCustomPriceInputChange
}: QuotationMainTableProps) => {
  const classes = useStyles();

  const onCopyText = (text: string) => {
    const newText = `${text}`;
    copyToClipboard(newText);
  };

  const itemRetailPrice = useCallback(
    (param?: number) => promoPriceToSRP(param, showSRP),
    [showSRP]
  );

  return (
    <Card>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            {hasCompatibility ? null : <TableCell>QTY</TableCell>}
            <TableCell>TYPE</TableCell>
            {/* TODO: fix later to add image quotation column */}
            {/* <TableCell>IMAGE</TableCell> */}
            <TableCell>PRODUCT</TableCell>
            <TableCell id="quotation-action-cell" />
            {!showCleanSrpQuote ? (
              <TableCell className={classes.promoPriceHeaderTxt}>
                🔥 PROMO PRICE 🔥
              </TableCell>
            ) : null}
            {showSRP ? <TableCell>STANDARD PRICE</TableCell> : null}
            <TableCell>{showSRP ? 'AMOUNT (SRP)' : 'AMOUNT'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: Quotation, i) => (
            <StyledTableRow hover key={i}>
              {hasCompatibility ? null : (
                <QuotationQuantityCell
                  data={item}
                  index={i}
                  hasCompatibility={hasCompatibility}
                  onChangeQuantity={onChangeQuantity}
                />
              )}
              <QuotationCategoryCell
                index={i}
                item={item}
                hasCompatibility={hasCompatibility}
                categoryOptions={categoryOptions}
                onChangeCategory={onChangeCategory}
                customInput={item?.product?.category_name || ''}
                onCustomCategInputChange={onCustomCategInputChange}
                isCategoryLoading={isCategoryLoading}
              />
              {/*TODO: fix later to allow image on quotation selected products */}
              {/* <QuotationProductImageCell item={item}/> */}
              <QuotationProductCell
                index={i}
                branchId={branchId}
                item={item}
                showSRP={showSRP}
                productOptions={productOptions}
                customProductInput={item?.product?.product_name || ''}
                onCustomProductInputChange={onCustomProductInputChange}
                onChangeProduct={onChangeProduct}
                isCategoryLoading={isCategoryLoading}
              />
              <TableCell id="quotation-action-cell">
                {item?.product?.product_name ? (
                  <Tooltip title="Copy">
                    <Button
                      style={{ padding: 0, margin: -15 }}
                      startIcon={<FileCopyIcon />}
                      onClick={() =>
                        onCopyText(item?.product?.product_name || '')
                      }
                    />
                  </Tooltip>
                ) : null}
                {item?.product?.product_name ? (
                  <Tooltip title="View product in ecomm">
                    <Button
                      style={{ padding: 0, margin: -15 }}
                      startIcon={<LinkIcon />}
                      onClick={() =>
                        openInNewTab(
                          `https://pcworth.com/product/${item?.product?.slug}`
                        )
                      }
                    />
                  </Tooltip>
                ) : null}
                {item?.product?.product_name ? (
                  <Tooltip title="View product in internal">
                    <Button
                      style={{ padding: 0, margin: -15 }}
                      startIcon={<DvrIcon />}
                      onClick={() =>
                        openInNewTab(
                          `/app/products/${item?.product?.product_id}`
                        )
                      }
                    />
                  </Tooltip>
                ) : null}
              </TableCell>
              {!showCleanSrpQuote ? (
                <QuotationPromoPriceCell
                  index={i}
                  item={item}
                  customPriceInput={
                    item?.product?.retail_price?.toString() || ''
                  }
                  onCustomPriceInputChange={onCustomPriceInputChange}
                />
              ) : null}
              {showSRP ? <QuotationSRPCell item={item} /> : null}
              <TableCell className={classes.amount}>
                {' '}
                {formatCurrency(
                  itemRetailPrice(item?.product?.retail_price) * item?.quantity
                )}
              </TableCell>
              <TableCell
                id="remove_row_icon"
                onClick={(e) => e.stopPropagation()}
              >
                {isCategoryLoading ? null : (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveItem(i);
                    }}
                  >
                    <ClearIcon color="secondary" fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
              {item?.warning_note ? (
                <TableCell onClick={(e) => e.stopPropagation()}>
                  <Tooltip title={item?.warning_note || ''}>
                    <IconButton
                      disabled={!item?.warning_note}
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <WarningIcon color="secondary" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              ) : null}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <QuotationTotalPrice
        items={items}
        showSRP={showSRP}
        showCleanSrpQuote={showCleanSrpQuote}
      />
    </Card>
  );
};

export const QuotationMainTable = React.memo(MainTable);
