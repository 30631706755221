export const RMAField = {
  ID: 'id',
  CUSTOMER_NAME: 'customer_name',
  CONTACT_NO: 'contact_no',
  REPORTED_PROBLEM: 'reported_problem',
  REMARKS: 'remarks',
  PRODUCT_ID: 'product_id',
  LISTING_ID: 'listing_id',
  STATUS: 'status',
  CREATED_BY: 'created_by',
  CREATED_AT: 'created_at',
  TECH: 'tech',
  LOCATION: 'location',
  RMA_IMAGES: 'images', // TODO: Change to RMA_IMAGES

  SERIAL_NO: 'serial_no', // FE ONLY
  PRODUCT_NAME: 'product_name' // FE ONLY
};

export const RMAFieldLabel = {
  ID: 'ID',
  CUSTOMER_NAME: 'Customer Name',
  CONTACT_NO: 'Contact No',
  SERIAL_NO: 'Serial No',
  REPORTED_PROBLEM: 'Reported Problem',
  REMARKS: 'Remarks',
  PRODUCT_NAME: 'Product Name',
  STATUS: 'Status',
  CREATED_BY: 'Created By',
  CREATED_AT: 'Created At',
  TECH: 'Tech',
  LOCATION: 'Location',
  RMA_IMAGES: 'Images'
};
