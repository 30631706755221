import { Chip, Typography } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router';
import { Rma } from 'src/types';
import { getRmaStatusViaId } from 'src/views/rma/util/rma-util';

interface Props {
  rma?: Rma;
  chipStyle?: React.CSSProperties;
}

export const RmaChip = ({ rma, chipStyle }: Props) => {
  const navigate = useNavigate();

  const onNavigateToRma = () => {
    if (rma?.id) {
      navigate(`/app/rma/${rma.id}`);
    }
  };

  return (
    <Chip
      onClick={onNavigateToRma}
      style={chipStyle}
      label={
        <Typography variant="subtitle2">
          {`RMA: ${getRmaStatusViaId(rma?.status_id)}`}
        </Typography>
      }
      size="medium"
      variant="outlined"
      color="default"
    />
  );
};
