/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { DeletableImg, DragAndDropImgUpload } from 'src/components';
import { Autocomplete } from '@material-ui/lab';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { RMAFormField } from '../../../redux/slices/rma/types';

interface Props {
  formikHookRef: any;
  formFields: RMAFormField[];
  onChange?: (fieldKey: string, newValue: any) => void; // Dont use this too much. Use formikHookRef instead
}

// RMA-TODO: Use later but for RMAs
// const { selectors: taptapCustomerSelectors } = slices.taptapCustomer;

const RMAForm = ({ formikHookRef, formFields, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const profiles: any = [];

  const fetchInternalUsers = async () => {
    // dispatch(taptapGetProfilesThunk()); // RMA-TODO: Get users of internal system
  };

  const handleFieldChange = (fieldKey: string, newValue: any) => {
    if (onChange) {
      onChange(fieldKey, newValue);
    }
    formikHookRef.setFieldValue(fieldKey, newValue);
  };

  const autocompleteFieldOptions = useCallback(
    (fieldKey: string) => {
      const foundField = formFields.find((field) => field.key === fieldKey);

      if (!foundField) {
        return [];
      }

      if (foundField?.type === 'profile') {
        return profiles.map((profile: any) => ({
          value: profile.display_name || '',
          label: profile.display_name || ''
        }));
      }

      return foundField?.options || [];
    },
    [formFields, profiles]
  );

  useEffect(() => {
    fetchInternalUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <form autoComplete="off">
        <Grid container spacing={3}>
          {formFields?.map((field) => {
            if (field?.conditional) {
              const dependentValue =
                formikHookRef?.values[field?.conditional?.dependsOn];
              if (!field?.conditional?.value?.includes(dependentValue)) {
                // Skip rendering this field if the condition is not met
                return null;
              }
            }

            if (field?.hidden) {
              return null;
            }

            return (
              <Grid
                item
                xs={12}
                key={field?.key}
                md={field?.grid === 'half' ? 6 : undefined}
              >
                {field?.type === 'text' ||
                field?.type === 'number' ||
                field?.type === 'longText' ? (
                  <TextField
                    disabled={field?.disabled}
                    id={field?.key}
                    fullWidth
                    label={field?.label}
                    name={field?.key}
                    type={field?.type}
                    multiline={field?.type === 'longText'}
                    minRows={field?.type === 'longText' ? 3 : undefined}
                    onChange={(e) =>
                      handleFieldChange(field?.key, e.target.value)
                    }
                    required={field?.required || false}
                    value={formikHookRef.values[field?.key] || ''}
                    onBlur={formikHookRef.handleBlur}
                    variant="outlined"
                    error={formikHookRef.errors[field?.key] ? true : false}
                    helperText={formikHookRef.errors[field?.key] || undefined}
                  />
                ) : null}

                {field?.type === 'autocomplete' || field?.type === 'profile' ? (
                  <Autocomplete
                    id={field?.key}
                    disabled={field?.disabled}
                    options={autocompleteFieldOptions(field?.key)}
                    getOptionLabel={(option) => option?.label || option || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value
                    }
                    value={formikHookRef.values[field?.key] || ''} // Ensure formikHookRef's value is valid
                    // onChange={(event, value) => {
                    //   formikHookRef.setFieldValue(
                    //     field?.key,
                    //     value?.value || ''
                    //   ); // Store only the primitive value
                    // }}
                    onChange={(event, value) =>
                      handleFieldChange(field?.key, value?.value || '')
                    }
                    // onBlur={() =>
                    //   formikHookRef.setFieldTouched(field?.key, true)
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field?.label}
                        variant="outlined"
                        error={
                          // formikHookRef.touched[field?.key] &&
                          Boolean(formikHookRef.errors[field?.key])
                        }
                        disabled={field?.disabled}
                        helperText={
                          // formikHookRef.touched[field?.key] &&
                          formikHookRef.errors[field?.key]
                        }
                      />
                    )}
                  />
                ) : null}

                {field?.type === 'boolean' && (
                  <>
                    <FormControlLabel
                      disabled={field?.disabled}
                      control={
                        <Checkbox
                          id={field?.key}
                          name={field?.key}
                          checked={
                            formikHookRef.values[field?.key] ? true : false
                          }
                          // onChange={formikHookRef.handleChange}

                          onChange={(e, checked) =>
                            handleFieldChange(field?.key, checked)
                          }
                          onBlur={formikHookRef.handleBlur}
                        />
                      }
                      label={field?.label}
                    />
                    {formikHookRef.errors[field?.key] ? (
                      <FormHelperText error>
                        {formikHookRef.errors[field?.key]}
                      </FormHelperText>
                    ) : null}
                  </>
                )}

                {field?.type === 'radio' && (
                  <>
                    <FormLabel id={field?.key}>{field?.label}</FormLabel>
                    <RadioGroup
                      row
                      id={field?.key}
                      name={field?.key}
                      value={formikHookRef.values[field?.key] || ''}
                      defaultValue={formikHookRef.values[field?.key]}
                      // onChange={formikHookRef.handleChange}
                      onChange={(e, value) =>
                        handleFieldChange(field?.key, value)
                      }
                    >
                      {field?.options?.map((option: any) => (
                        <FormControlLabel
                          disabled={field?.disabled}
                          id={option?.value}
                          key={option?.value}
                          value={option?.value}
                          control={
                            <Radio
                              onClick={() => {
                                // Check if the current option is already selected
                                if (
                                  formikHookRef.values[field?.key] ===
                                  option.value
                                ) {
                                  formikHookRef.setFieldValue(field?.key, ''); // Unselect by setting to an empty string
                                } else {
                                  formikHookRef.setFieldValue(
                                    field?.key,
                                    option.value
                                  ); // Select the new value
                                }
                              }}
                            />
                          }
                          label={option?.label}
                        />
                      ))}
                    </RadioGroup>

                    {formikHookRef?.errors[field?.key] && (
                      <FormHelperText error>
                        {formikHookRef?.errors[field?.key]}
                      </FormHelperText>
                    )}
                  </>
                )}

                {field?.type === 'image' && (
                  <>
                    <Grid container spacing={2}>
                      {formikHookRef.values[field?.key]?.map(
                        (img: any, i: any) => (
                          <Grid key={img?.img_url || img?.file_url} item>
                            <DeletableImg
                              index={i}
                              imgSrc={img?.img_url || img?.file_url}
                              onDelete={() => {
                                const newImages = formikHookRef.values[
                                  field?.key
                                ].filter(
                                  (image: any, index: any) => index !== i
                                );
                                // formikHookRef.setFieldValue(
                                //   field?.key,
                                //   newImages
                                // );

                                handleFieldChange(field?.key, newImages);
                              }}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                    <div style={{ marginTop: 10 }} />
                    <DragAndDropImgUpload
                    maxSize={10485760}
                      title="Drag or select photos"
                      onImageSelected={(newImgs: any) => {
                        const newImages = newImgs?.map((img: File) => ({
                          file: img,
                          img_url: URL.createObjectURL(img),
                          created_by: undefined,
                          created_at: new Date().toISOString()
                        }));

                        // if (!formikHookRef.values[field?.key]) {
                        //   //  Minsan kasi nag na null eh :(
                        //   // formikHookRef.setFieldValue(field?.key, newImages);
                        //   handleFieldChange(field?.key, newImages);
                        // } else {
                        //   formikHookRef.setFieldValue(field?.key, [
                        //     ...formikHookRef.values[field?.key],
                        //     ...newImages
                        //   ]);
                        //   handleFieldChange(field?.key, newImages);
                        // }

                        const updatedImages = formikHookRef.values[field?.key]
                          ? [...formikHookRef.values[field?.key], ...newImages]
                          : newImages;

                        handleFieldChange(field?.key, updatedImages);
                      }}
                    />
                  </>
                )}
              </Grid>
            );
          })}
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default RMAForm;
