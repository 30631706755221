import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, GetInventoryResponse } from 'src/types';
import {
  CreateRmaRequest,
  CreateRmaResponse,
  DeleteRmaImageResponse,
  DeleteRmaResponse,
  GetRmasResponse,
  GetRmaViaIdResponse,
  RMAData,
  UpdateRmaResponse,
  UploadRmaImagesRequest,
  UploadRmaImagesResponse
} from './types';
import { cleanSN, convertImageFileToBase64 } from 'src/utils';
import { cloneDeep } from 'lodash';

export const uploadRmaImagesThunk = createAsyncThunk<
  CommonAxiosResponse<UploadRmaImagesResponse>,
  UploadRmaImagesRequest,
  { state: RootState }
>('rma/createRma', async (data) => {
  const rma_images = await Promise.all(
    data?.images?.map((x) => convertImageFileToBase64(x?.file))
  );

  const imagesPayload = {
    rma_transaction_id: data?.rma_id,
    rma_images
  };

  const uploadRmaImgsRes = await Api.post(
    `/rma/rma_images/upload`, // TODO: CHANGE THIS TO `/rma/RMA_IMAGES/upload`,
    imagesPayload
  );

  return uploadRmaImgsRes;
});

export const deleteRmaImageThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteRmaImageResponse>,
  number,
  { state: RootState }
>('rma/createRma', async (rma_transaction_image_id) => {
  const deleteRmaImgRes = await Api.delete(
    `/rma/rma_images/delete/${rma_transaction_image_id}`  //  TODO: CHANGE THIS TO `/rma/RMA_IMAGES/delete/x`,
  );

  return deleteRmaImgRes;
});

export const createRmaThunk = createAsyncThunk<
  CommonAxiosResponse<CreateRmaResponse>,
  CreateRmaRequest,
  { state: RootState }
>('rma/createRma', async (data) => {
  const clonedDataPayload = cloneDeep(data);
  delete clonedDataPayload.images; // Delete images from payload to avoid error

  const createRmaRes: CommonAxiosResponse<CreateRmaResponse> = await Api.post(
    `/rma/create`,
    clonedDataPayload
  );

  if (data?.images && data?.images?.length > 0) {
    const rma_transaction_id =
      createRmaRes?.originalData?.data?.rma_transaction_id;

    const rma_images = await Promise.all(
      data?.images?.map((x) => convertImageFileToBase64(x?.file))
    );

    const imagesPayload = {
      rma_transaction_id,
      rma_images
    };

    await Api.post(
      `/rma/rma_images/upload`, // TODO: CHANGE THIS TO `/rma/RMA_IMAGES/upload`,
      imagesPayload
    );
  }

  return createRmaRes;
});

export const getRmasThunk = createAsyncThunk<
  CommonAxiosResponse<GetRmasResponse>,
  undefined,
  { state: RootState }
>('rma/getRmas', async (_, thunkApi) => {
  const data = thunkApi.getState().rma.rmaGetPayload;
  const response = await Api.get('/rma/get', { ...data, limit: 999 });
  return response;
});

export const getRmaViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetRmaViaIdResponse>,
  number,
  { state: RootState }
>('rma/getRmaViaIdThunk', async (id) => {
  const response = await Api.get(`/rma/get/${id}`);
  return response;
});

export const updateRmaThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateRmaResponse>,
  RMAData,
  { state: RootState }
>('rma/updateRmaThunk', async (data) => {
  const response = await Api.patch(`/rma/update/${data.rma_id}`, data);
  return response;
});

export const rmaDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteRmaResponse>,
  number,
  { state: RootState }
>('rmas/rmaDeleteThunk', async (id) => {
  const response = await Api.delete(`/rma/delete/${id}`);
  return response;
});

// TODO: REMOVE THIS SHIT. THIS IS V1 RMA WHICH IS SHITTY
export const getRmasViaTransactionNoThunk = createAsyncThunk<
  CommonAxiosResponse<any>,
  string | undefined,
  { state: RootState }
>('rma/getRmasViaTransactionNoThunk', async (transaction_no) => {
  const response = await Api.get(`/rma/getRmasViaTransactionNo`, {
    transaction_no
  });
  return response;
});

export const getRmaTransactionNoViaSn = createAsyncThunk<
  CommonAxiosResponse<CreateRmaResponse>,
  CreateRmaRequest,
  { state: RootState }
>('rma/createRma', async (data) => {
  const response = await Api.post(`/rma/create`, data);
  return response;
});

export const getRmaSNTransactionNo = createAsyncThunk<
  CommonAxiosResponse<GetInventoryResponse>,
  string | undefined,
  { state: RootState }
>('rma/getSNTransactionNo', async (serial_no, thunkApi) => {
  const userBranches = thunkApi?.getState().user?.userInfo?.branches || [];
  const branch_ids = userBranches?.map((x) => x?.id);

  let request = {
    status: [
      'available',
      'transfer stock',
      'defective',
      'on hold',
      'purchased'
    ],
    branch_ids,
    category_id: null,
    page: 1,
    manufacturer_id: null,
    limit: 5,
    serial_no,
    action: 'list'
  };

  let sn = null;

  if (request?.serial_no) {
    sn = cleanSN(request.serial_no);
    request = { ...request, serial_no: sn };
  }

  const response = await Api.post('/inventory/get', request);
  return response;
});
