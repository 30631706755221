import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const component = () => {
  return (
    <TableRow>
      <TableCell colSpan={10}>
        <Alert severity="info">
          <Typography variant="h4">
            No Result.
          </Typography>
        </Alert>
      </TableCell>
    </TableRow>
  );
};

export const RMAEmptySearchView = React.memo(component);
