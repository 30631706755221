import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Module {
  url: string;
  title: string;
}

interface State {
  recentlyViewedModule: Module[];
}

const initialState: State = {
  recentlyViewedModule: []
};

const slice = createSlice({
  name: 'recentlyViewedModule',
  initialState,
  reducers: {
    addModulesToList(state, action: PayloadAction<Module>) {
      const maxItems = 5;
      const { recentlyViewedModule } = state;

      let recentlyCopy = [...recentlyViewedModule];

      recentlyCopy = recentlyCopy.filter(
        (item) => item.url !== action.payload.url
      );

      recentlyCopy.unshift(action.payload);

      if (recentlyCopy.length > maxItems) {
        recentlyCopy.pop();
      }

      state.recentlyViewedModule = recentlyCopy;
    }
  }
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions
};
