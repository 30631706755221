import { GpuPartDetailsFields, PartFields } from 'src/types';

export const gpuPartsFields: PartFields<GpuPartDetailsFields>[] = [
  {
    label: 'Memory',
    part_property: 'memory',
    hint: '2GB, 4GB, 8GB',
    transformRule: ['all-caps', 'no-spaces'],
    options: [
      '2GB',
      '4GB',
      '6GB',
      '8GB',
      '10GB',
      '12GB',
    ],
    type: 'string',
    required: true
  },
  {
    label: 'Interface',
    part_property: 'interface',
    type: 'string',
    options: ['PCI Express 4.0', 'PCI Express 5.0'],
    transformRule: ['all-caps'],
    required: false
  },
  {
    label: 'Boost Clock',
    part_property: 'boost_clock',
    transformRule: ['no-spaces'],
    hint: '2581 MHz',
    type: 'string',
    required: false
  },
  {
    label: 'Core Clock',
    part_property: 'core_clock',
    hint: '2581 MHz',
    type: 'string',
    required: false
  },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  },
  {
    label: 'Length (mm)',
    part_property: 'length',
    // unit: 'mm',
    hint: 'enter length in mm',
    type: 'number',
    required: true
  }

];
