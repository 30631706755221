export enum StockFilter {
  All = 'all',
  HasStocks = 'has_stocks',
  NoStocks = 'no_stocks',
  WithSales = 'with_sales'
}

export enum SortFlag {
  Ascending = 'asc',
  Descending = 'desc',
  Default = 'default'
}
