/* eslint-disable no-unused-vars */
import {
  Typography,
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  CardContent,
  Card,
  Divider,
  ListItemIcon
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import useUserInfo from 'src/hooks/user/use-user-info';
import { toTitleCase } from 'src/utils';
import { slices } from 'src/redux';
import { useSelector } from 'react-redux';

const { selectors: favoriteModuleSelectors } = slices.favorites;
import { useAppDispatch } from 'src/redux';
import { Divide } from 'react-feather';

const useStyles = makeStyles(() => ({
  avatar: {
    display: 'block',
    margin: '1em auto',
    width: 250,
    height: 250
  },
  navigation: {
    marginTop: 2,
    height: '180px'
  },
  nameAndDate: {
    fontSize: '1.7em'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  flexRow: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: '10px'
  },
  button: {
    marginTop: 'auto'
  }
}));

const { selectors: recentlyVisitedModuleSelector } = slices.recentView;
const { actions: addModulesToListAction } = slices.recentView;

const WelcomePage = () => {
  const classes = useStyles();
  const { userDetails } = useUserInfo();
  const dispatch = useAppDispatch();

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const recentlyVisitedUrls = useSelector(
    recentlyVisitedModuleSelector.selectRecentlyVisitedUrls
  );

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    href: string,
    title: string
  ) => {
    event.preventDefault(); // Prevent default navigation

    if (event.ctrlKey || event.metaKey || event.button === 2) {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: href,
          title: title
        })
      );
      window.open(href);
      window.location.href = href;
    } else {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: href,
          title: title
        })
      );
      window.location.href = href;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const favoriteModules = useSelector(
    favoriteModuleSelectors.selectFavoriteModule
  );

  return (
    <Box sx={{ p: 2, height: '90vh' }}>
      <Typography variant="h4" gutterBottom style={{ fontSize: '2em' }}>
        Welcome to the Internal Systems
      </Typography>
      <Box className={classes.flexRow}>
        <Typography variant="h6" className={classes.nameAndDate}>
          {toTitleCase(userDetails?.first_name ?? '')}
        </Typography>
        <Typography variant="h6" className={classes.nameAndDate}>
          {/* intended space */}
          {currentDateTime.toLocaleDateString()}{' '}
          {currentDateTime.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom style={{ marginBottom: '1em'} }>
        This platform allows you to manage various aspects of the business,
        including customer management, transactions, inventory, and more. Use
        the navigation links below to get started.
      </Typography>
      {/* TODO: Make this recently visited in the future. */}
      <Divider />
      <Grid container spacing={2} style={{ marginTop: 5, marginBottom: '1em' }}>
        <Grid item xs={12}>
          <Typography variant="h5">Shortcut Links</Typography>
        </Grid>
        <Grid item xs={12}>
          {favoriteModules.length === 0 ? (
            <Typography variant="subtitle2" color="textSecondary">
              No Shortcut Links
            </Typography>
          ) : (
            <Box
              display="flex"
              flexWrap="wrap"
              overflow="auto"
              style={{ gap: 5 }}
            >
              {favoriteModules.map((module, index) => (
                <Button
                  key={index}
                  component="a"
                  href={module.url}
                  style={{
                    backgroundColor: '#d5d5d5',
                    width: '300px',
                    textAlign: 'center',
                    padding: '10px'
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '14px'
                        }}
                      >
                        {module.title}
                      </Typography>
                    }
                  />
                </Button>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        spacing={1}
        style={{ marginTop: '2em', marginBottom: '1em' }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Recently Viewed Modules</Typography>
        </Grid>
        <Grid item xs={12}>
          <List style={{ width: '100%' }}>
            {recentlyVisitedUrls.length === 0 ? (
              <Typography variant="subtitle2" color="textSecondary">
                No recently viewed modules
              </Typography>
            ) : (
              recentlyVisitedUrls.map((module, index) => (
                <ListItem key={index}>
                  <ListItemIcon style={{ minWidth: '20px' }}>•</ListItemIcon>
                  <ListItemText
                    primary={
                      <a
                        href={module.url}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                          display: 'block',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          width: '300px',
                        }}
                      >
                        {module.title}
                      </a>
                    }
                  />
                </ListItem>
              ))
            )}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelcomePage;
