import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import * as thunks from './thunks';
import { GetRmasRequest, RMAData } from './types';

type State = {
  rmas: RMAData[];
  rmaGetPayload: GetRmasRequest;
};

const initialState: State = {
  rmas: [],
  rmaGetPayload: {
    limit: 100,
    status: '',
    serial_no: '',
    customer_name: '',
    product_name: ''
  }
};

const slice = createSlice({
  name: 'rmas',
  initialState,
  reducers: {
    updateGetRmaPayload: (
      state,
      { payload }: PayloadAction<GetRmasRequest>
    ) => {
      state.rmaGetPayload = { ...state.rmaGetPayload, ...payload };
    }
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.updateRmaThunk.fulfilled,
      (state, { payload, meta }) => {
        const rmaLists = cloneDeep(state.rmas);

        const index = rmaLists.findIndex(
          (x) => x?.rma_id === meta?.arg?.rma_id
        );

        if (index > -1) {
          if (rmaLists[index] && payload?.originalData?.data) {
            rmaLists[index] = payload?.originalData.data;
            state.rmas = rmaLists;
          } 
        }
      }
    );
    builder.addCase(thunks.getRmasThunk.fulfilled, (state, { payload }) => {
      state.rmas = payload?.originalData?.data || [];
    });
    builder.addCase(thunks.rmaDeleteThunk.fulfilled, (state, actions) => {
      const rmaId = actions.meta.arg;
      const clonedProducts = cloneDeep(state.rmas);
      const index = state?.rmas?.findIndex((x) => x?.rma_id === rmaId);
      if (index > -1) {
        clonedProducts.splice(index, 1);
      }
      state.rmas = clonedProducts;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
