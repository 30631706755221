import { FastMovingProductsData } from 'src/redux/slices/fast-moving-products';

const excludeConst = [
  'product_id',
  'gross',
  'suppliers',
  'last_dealer_price',
  'last_retail_price',
  'margin_percentage'
];

export const priceColumn = ['last_dealer_price', 'last_retail_price', 'gross'];

export const fastMovingHeaderKeys = (
  fastMovingData?: FastMovingProductsData[]
) => {
  if (!fastMovingData || fastMovingData.length === 0) return [];

  // Extract unique keys excluding `excludeConst`, while ensuring `product_name` is not included
  const uniqueKeys = new Set(
    fastMovingData?.flatMap((data) =>
      Object.keys(data).filter(
        (key) => !excludeConst.includes(key) && key !== 'product_name'
      )
    )
  );

  // Convert Set to an array for sorting
  const sortedKeys = Array.from(uniqueKeys).sort((keyA, keyB) => {
    const isSoldA = keyA.endsWith('_sold');
    const isSoldB = keyB.endsWith('_sold');

    const isAvailableA = keyA.endsWith('_available');
    const isAvailableB = keyB.endsWith('_available');

    if (isSoldA && isSoldB) {
      return keyA.localeCompare(keyB); // Alphabetical sorting for _sold keys
    }

    if (isAvailableA && isAvailableB) {
      return keyA.localeCompare(keyB); // Alphabetical sorting for _available keys
    }

    if (isSoldA) return -1; // Prioritize _sold properties first
    if (isSoldB) return 1;

    if (keyA === 'total_sold') return 1; // Place `total_sold` after all `_sold`
    if (keyB === 'total_sold') return -1;

    if (isAvailableA) return -1; // Prioritize _available after `_sold` & `total_sold`
    if (isAvailableB) return 1;

    if (keyA === 'total_available') return 1; // Place `total_available` at the end
    if (keyB === 'total_available') return -1;

    return keyA.localeCompare(keyB); // Default alphabetical sorting
  });

  // Ensure "product_name" is always first
  return [
    'product_name',
    ...sortedKeys,
    'last_dealer_price',
    'last_retail_price',
    'margin_percentage',
    'gross',
    'suppliers'
  ];
};

export const fastMovingSortedData = (
  fastMovingData?: FastMovingProductsData[]
) => {
  return fastMovingData?.map((data) => {
    // Extract product_name and other specified properties separately
    const {
      product_name,
      gross,
      suppliers,
      last_dealer_price,
      last_retail_price,
      margin_percentage,
      ...rest
    } = data;

    const sortedEntries = Object.entries(rest).sort(([keyA], [keyB]) => {
      const isSoldA = keyA.endsWith('_sold');
      const isSoldB = keyB.endsWith('_sold');

      const isAvailableA = keyA.endsWith('_available');
      const isAvailableB = keyB.endsWith('_available');

      if (isSoldA && isSoldB) {
        return keyA.localeCompare(keyB); // Sort _sold keys alphabetically
      }
      if (isAvailableA && isAvailableB) {
        return keyA.localeCompare(keyB); // Sort _available keys alphabetically
      }

      if (isSoldA) return -1; // Prioritize _sold properties first
      if (isSoldB) return 1;

      if (keyA === 'total_sold') return 1; // Place total_sold after all _sold
      if (keyB === 'total_sold') return -1;

      if (isAvailableA) return -1; // Prioritize _available after _sold
      if (isAvailableB) return 1;

      if (keyA === 'total_available') return 1; // Place total_available at the end
      if (keyB === 'total_available') return -1;

      return 0; // Default to no change in order
    });

    return {
      product_name, // Ensure product_name is always first
      ...Object.fromEntries(sortedEntries), // Insert sorted key-value pairs
      last_dealer_price,
      last_retail_price,
      gross,
      suppliers,
      margin_percentage
    };
  });
};
