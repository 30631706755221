import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { TableCell, TableRow, colors, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { AlternatingColorTableRow } from 'src/components';
import { TransferStockLogsData } from 'src/redux/slices/transfer-stock-logs';
import { useTransferStockLogs } from 'src/hooks/transfer-stock-logs';
import { TransferLogAccordion } from './TransferStockLogAccordion';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';

const { cancelTransferStockLogsSerialThunk } = slices.transferStockLogs;

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  hoverPointer: { cursor: 'pointer' },
  tableRow: {
    height: 35,
    padding: 30
  },
  tableCell: {
    border: 'none',
    padding: '1rem',
    borderBottom: '1px solid gray'
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  }
}));

interface Props {
  logsData?: TransferStockLogsData;
}

const component: FC<Props> = ({ logsData }) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const {
    fetchedStNo,
    setFetchedStNo,
    isTransferLogsSerialLoading,
    transferLogsSerialData,
    setIsTransferLogsSerialLoading,
    getTransferStockLogsThroughSt
  } = useTransferStockLogs();

  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [cancelErrorMessages, setCancelErrorMessages] = useState<{
    [key: string]: string;
  }>({});

  const keyCounter = useCallback((obj: { [key: string]: string }) => {
    return Object.keys(obj).length || 0;
  }, []);

  const handleItemsCancellation = useCallback(
    async (logIds: number[], reason: string) => {
      let errors: { [key: string]: string } = {};

      setIsTransferLogsSerialLoading(true);
      setCancelErrorMessages({});

      for (const id of logIds) {
        const res = unwrapResult(
          await dispatch(
            cancelTransferStockLogsSerialThunk({
              log_id: id,
              cancel_reason: reason
            })
          )
        );

        if (!res.success) {
          errors[id] = `${Object.values(res?.errors)[0]}`;
        }

        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      // display snackBar if all request are error
      if (keyCounter(errors) > 0 && keyCounter(errors) === logIds.length) {
        let strFormatErrors = Object.entries(errors).map(
          ([key, value]) => `${key}: ${value}`
        );

        snackBar.show({
          severity: 'error',
          message: strFormatErrors.join(', '),
          useSound: true
        });

        setCancelErrorMessages(errors);
        console.error(strFormatErrors);
        setIsTransferLogsSerialLoading(false);
        return;
      }

      getTransferStockLogsThroughSt(logsData?.st_no);

      snackBar.show({
        severity: 'success',
        message: 'Cancelled transfer items successfully',
        useSound: true
      });

      // Track failed cancel errors
      if (Object.values(errors)[0]) {
        setCancelErrorMessages(errors);
        console.error(errors);
      }

      setIsTransferLogsSerialLoading(false);
    },
    [
      dispatch,
      getTransferStockLogsThroughSt,
      keyCounter,
      logsData,
      setIsTransferLogsSerialLoading,
      snackBar
    ]
  );

  const onHandleViewTransferStockLogsSerial = useCallback(
    (st_no?: string) => {
      if (isExpand) {
        setIsExpand(!isExpand);
        return;
      }
      //if st exist no action needed
      if (fetchedStNo.some((st) => st === st_no)) {
        setIsExpand(!isExpand); //flag action
        return;
      }
      getTransferStockLogsThroughSt(st_no);
      setFetchedStNo((prev) => [...prev, st_no || '']);
      setIsExpand(!isExpand);
    },
    [fetchedStNo, getTransferStockLogsThroughSt, isExpand, setFetchedStNo]
  );

  //if there is a new request reset all
  useEffect(() => {
    if (logsData) {
      setIsExpand(false);
      setFetchedStNo([]);
    }
  }, [logsData, setFetchedStNo]);

  return (
    <Fragment>
      <AlternatingColorTableRow
        hover
        className={classes.hoverPointer}
        onClick={() => onHandleViewTransferStockLogsSerial(logsData?.st_no)}
      >
        <TableCell className={classes.tableCell} width={'5%'}>
          {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.with_issue === 0 ? (
            <CheckCircleIcon style={{ color: colors.green[600] }} />
          ) : (
            <CancelIcon color="secondary" />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>{logsData?.st_no}</TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.transferred_by}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.transferred_date}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.from_branch}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.to_branch}
        </TableCell>
      </AlternatingColorTableRow>

      {isExpand && (
        <TableRow>
          <TableCell colSpan={7}>
            <TransferLogAccordion
              stNo={logsData?.st_no}
              cancelErrors={cancelErrorMessages}
              stBarcode={logsData?.st_no_barcode}
              dateTransferred={logsData?.transferred_date}
              origin={logsData?.from_branch}
              receiving={logsData?.to_branch}
              processedBy={logsData?.transferred_by}
              isLoading={isTransferLogsSerialLoading}
              setIsLoading={(val: boolean) =>
                setIsTransferLogsSerialLoading(val)
              }
              isOpen={isExpand}
              handleItemsCancellation={handleItemsCancellation}
              transferLogsSerialData={transferLogsSerialData || []}
            />
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export const TransferStockRow = memo(component);
