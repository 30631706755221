import React from 'react';
import { Link } from '@material-ui/core';

interface Props {
  title: string | JSX.Element;
  href?: string;
  onClick?: () => void;
  extraProps?: any;
  target?: string;
  rel?: string;
  openInNewTab?: boolean;
  font?: string;
}

export const LinkComponent = ({
  target = undefined,
  rel = undefined,
  title = '',
  href,
  extraProps,
  openInNewTab = false,
  font
}: Props) => {
  return (
    <Link
      {...extraProps}
      href={href}
      target={openInNewTab ? '_blank' : target}
      rel={openInNewTab ? 'noopener noreferrer' : rel}
      style={{
        fontFamily: font,
        fontWeight: 700
      }}
    >
      {title}
    </Link>
  );
};
