import React, { FC, memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { copyToClipboard, formatCurrency } from 'src/utils';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { LinkComponent } from 'src/components';
import { priceColumn } from 'src/utils/fast-moving-products';

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 16,
    border: '1px solid black',
    maxHeight: 600,
    overflow: 'auto'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    minWidth: 800
  },
  tableCell: {
    border: '1px solid black',
    padding: 8,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    zIndex: 0
  },
  tableHeaderCell: {
    border: '1px solid black',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 10
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#e0e0e0'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#ffffff'
    },
    '&:hover': {
      background: '#bdbdbd',

      // Ensure sticky column gets the same hover effect
      '& $stickyColumn': {
        background: '#bdbdbd'
      }
    }
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 11,
    backgroundColor: '#f0f0f0',
    border: '1px solid black',

    // Ensure hover applies from row hover
    '&:hover, $tableRow:hover &': {
      background: '#bdbdbd'
    }
  },
  stickyHeaderColumn: {
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 12,
    backgroundColor: '#f0f0f0',
    borderBottom: '2px solid gray'
  }
});

interface Props {
  headers?: string[];
  fastMovingData?: any[];
}

const Component: FC<Props> = ({ headers, fastMovingData }) => {
  const classes = useStyles();

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers?.map((header, index) => (
              <TableCell
                key={header}
                className={`${classes.tableHeaderCell} ${
                  index === 0 ? classes.stickyHeaderColumn : ''
                }`}
              >
                {header.replace(/_/g, ' ').toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fastMovingData?.map((data: any, rowIndex: number) => (
            <TableRow key={rowIndex} className={classes.tableRow}>
              {headers?.map((header, colIndex) => (
                <TableCell
                  className={`${classes.tableCell} ${
                    colIndex === 0 ? classes.stickyColumn : ''
                  }`}
                  key={colIndex}
                >
                  {header === 'product_name' ? (
                    <Box
                      display={'flex'}
                      style={{ textAlign: 'left', columnGap: '.5em' }}
                    >
                      <FileCopyIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => onHandleCopyProductName(data[header])}
                      />
                      <Box>
                        <LinkComponent
                          openInNewTab={true}
                          href={`/app/products/${data?.product_id}`}
                          title={`${data[header] ?? '--'}`}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {priceColumn.includes(header)
                        ? formatCurrency(data[header] ?? 0)
                        : header === 'margin_percentage'
                        ? `${data[header] ?? 0}%`
                        : data[header] ?? 0}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const FastMovingProductTableV2 = memo(Component);
